body {
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* .css-1bz0mms-MuiButtonBase-root-MuiListItem-root.Mui-selected.css-1bz0mms-MuiButtonBase-root-MuiListItem-root.Mui-selected{
  border-radius: 12px;
}

.css-1fnkbgs-MuiButtonBase-root-MuiListItem-root.Mui-selected.css-1fnkbgs-MuiButtonBase-root-MuiListItem-root.Mui-selected{
  background-color: rgb(248, 248, 140);
  border-radius: 12px;
}

.css-1fnkbgs-MuiButtonBase-root-MuiListItem-root:hover{
  background-color: rgb(247, 247, 192) !important;
  border-radius: 12px;
} */
